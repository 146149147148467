.job {
  margin: 5px 0;
}

.date {
  display: inline-block;
  width: 185px;
}

.wrapper {
  display: inline-block;
  vertical-align: top;
  max-width: 800px;
}

.content {
  margin: 5px 0;

  ul {
    margin: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    margin-left: 30px;
  }
}


@media only print {
  .date {
    width: 150px;
  }
  .wrapper {
    max-width: 550px;
  }
}
