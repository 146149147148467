.section {
  ul {
    margin: 0;
  }
}

.name {
  border-bottom: 1px solid #6c757d;
  margin: 10px 0 5px 0;
}
