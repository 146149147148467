.navBar {
  position: relative;
  height: 38px;
  width: 100%;
  border-bottom: 1px solid #6c757d;
}

.nav {
  position: relative;
  float: right;

  a {
    color: #6c757d;
  }

  i {
    line-height: 32px;
    color: #6c757d;
    margin: 0 5px;
  }
}

.mobile,
.email,
.linkedin {
  display: inline-block;
  margin-right: 5px;
}

.downloadPDF,
.github {
  display: none;
}

@media only screen {
  .mobile,
  .email,
  .linkedin {
    display: none;
  }

  .downloadPDF,
  .github {
    display: inline;
  }
}
