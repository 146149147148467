.container {
  position: relative;
  width: 100%;
  //font-family: Monospace, sans-serif;
  font-family: sans-serif;
}

@media (min-width: 768px) {
  .container {
    width: 75%;
    margin: 0 auto;
  }
}